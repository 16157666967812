.mid-pane-background-dark {
  background: linear-gradient(
      136deg,
      rgba(167, 24, 255, 0.1) 0%,
      rgba(244, 240, 252, 0.1) 50.52%,
      rgba(246, 91, 56, 0.1) 100%
    ),
    var(--white, #2a2332);
}

.mid-pane-background-light {
  background: linear-gradient(
      136deg,
      rgba(167, 24, 255, 0.1) 0%,
      rgba(244, 240, 252, 0.1) 50.52%,
      rgba(246, 91, 56, 0.1) 100%
    ),
    var(--white, #fff);
}

.mid-pane-dotted-background {
  > div:nth-of-type(1) {
    background-image: url("../../../../assets/images/background-dot.svg");
  }

  > div:nth-of-type(2) {
    background-image: url("../../../../assets/images/background-dot-fade-start.svg");
  }

  > div:nth-of-type(3) {
    background-image: url("../../../../assets/images/background-dot-fade-mid-start.svg");
  }

  > div:nth-of-type(4) {
    background-image: url("../../../../assets/images/background-dot-fade-mid-end.svg");
  }

  > div:nth-of-type(5) {
    background-image: url("../../../../assets/images/background-dot-fade-end.svg");
  }
}
